<template>
  <div class="o-office-detail">
    <div class="o-office-detail__office-information">
      <div class="o-office-detail__item">
        <ALabel>事業所ID</ALabel>
        <ABody>{{ office.id }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>事業所名</ALabel>
        <ABody>{{ office.officeName }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>事業所住所</ALabel>
        <ABody>{{ office.officeAddress }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>事業所電話番号</ALabel>
        <ABody>{{ office.officeTel }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>窓口担当者メールアドレス</ALabel>
        <ABody>{{ office.contactPersonEmail }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>窓口担当者役職</ALabel>
        <ABody>
          {{
            office.contactPersonPosition ?
            office.contactPersonPosition.name : ''
          }}
        </ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>窓口担当者名</ALabel>
        <ABody>{{ office.contactPersonName }}</ABody>
      </div>

      <div class="o-office-detail__item">
        <ALabel>請求先住所</ALabel>
        <ABody>{{ office.invoiceAddress }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>請求先電話番号</ALabel>
        <ABody>{{ office.invoiceTel }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>請求担当者メールアドレス</ALabel>
        <ABody>{{ office.invoicePersonEmail }}</ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>請求担当者役職</ALabel>
        <ABody>
          {{
            office.invoicePersonPosition ?
            office.invoicePersonPosition.name : ''
          }}
        </ABody>
      </div>
      <div class="o-office-detail__item">
        <ALabel>請求担当者名</ALabel>
        <ABody>{{ office.invoicePersonName }}</ABody>
      </div>
    </div>

    <div class="o-office-detail__users">
      <div class="o-office-detail__users-link">
        <router-link :to="`${office.id}/users/create`">従業員登録 ></router-link>
      </div>
      <OUserTable :users="office.users"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ALabel from '@/components/atoms/ATypography/ALabel/index.vue'
import ABody from '@/components/atoms/ATypography/ABody/index.vue'
import OUserTable from '@/components/organisms/OUserTable/index.vue'

export default {
  name: 'OOfficeDetail',
  components: {
    ALabel,
    ABody,
    OUserTable
  },
  props: {
    officeId: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.getOffice(this.officeId)
  },
  computed: {
    ...mapGetters('adminOffice', ['office'])
  },
  methods: {
    ...mapActions('adminOffice', ['getOffice'])
  }
}
</script>

<style lang="scss" scoped>
.o-office-detail {
  &__office-information {
    display: flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
  }
  &__item {
    width: 41%;
    margin: 10px 0;
  }
  &__users {
    margin: 20px auto;
  }
  &__users-link {
    height: 20px;
    margin-bottom: 24px;
    position: relative;
    a {
      color: #1179ce;
      text-decoration: none;
      position: absolute;
      right: 0;
    }
  }
}
</style>
