<template>
  <div class="o-user-table">
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>従業員一覧</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="pagination"
        no-data-text="従業員が登録されていません。"
        class="elevation-1">
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'OUserTable',
  props: {
    users: {
      type: Array,
      default: () => []
    },
    fetch: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    headers: [
      { text: '従業員ID', value: 'id' },
      { text: '従業員名', value: 'name' },
      { text: 'ログインID', value: 'loginId' },
      { text: '入社年月', value: 'joinedCompanyDate' },
      { text: '誕生日', value: 'birthday' },
    ],
    pagination: {
      descending: true,
      rowsPerPage: 10
    }
  })
}
</script>
