<template>
  <div class="admin-office-detail">
    <AHeading :level="1" class="admin-office-detail__heading">事業所詳細</AHeading>
    <ALink back>戻る</ALink>
    <OOfficeDetail :officeId="officeId" />
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import ALink from '@/components/atoms/ATypography/ALink/index.vue'
import OOfficeDetail from '@/components/organisms/OOfficeDetail/index.vue'

export default {
  name: 'AdminOfficeDetail',
  components: {
    AHeading,
    ALink,
    OOfficeDetail
  },
  data: () => ({
    officeId: 0
  }),
  created() {
    this.officeId = Number(this.$route.params.officeId)
  }
}
</script>

<style lang="scss" scoped>
.admin-office-detail {
  &__heading {
    margin: 20px auto;
  }
}
</style>
